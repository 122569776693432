<template>
    <div>
        <vue-header title="关注公众号" :fixed="true"/>
        <div>
            <div class="assess-alert flex flex-x flex-y"><van-icon name="checked" />评估报告已生成！</div>
            <div class="identify">请长按识别下方二维码</div>
            <div class="identify">查看评估报告</div>
            <div class="assess-alt">(未关注公众号请先关注)</div>
            <div class="qrcode" v-if="qrcode">
                <img :src="qrcode"/>
            </div>
        </div>
    </div>
</template>
<script>
    import { makecode , getDomain } from "@/utils";
    import vueHeader from '@/components/header';
    import { mapState, mapActions, mapMutations } from "vuex";
    export default {
        name: 'qr_report',
        components:{
            vueHeader
        },
        data(){ 
            return {
                qrcode:''
            }
        },
        created:function(){
            this.axiosQr({
                data:{
                    report_id:this.$route.params.id,
                    appid:getDomain()
                    //appid:'wx0588aef9780e1932'
                },
                fun:(data)=>{
                    this.qrcode = data.qr_code;
                }
            })
        },
        computed:{
            
        },
        methods: {
            ...mapActions('redEnvelope',['axiosQr']),
        }
    }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'qr_report';
</style>